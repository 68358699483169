import React, { memo } from 'react';
import { func, string, number } from 'prop-types';
import { BiChevronDown } from 'react-icons/bi';

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  MenuButton,
  Menu,
  InputGroup,
  Icon,
  MenuList,
  MenuItem,
  Select,
} from '@chakra-ui/react';

import { COUNTRY_CODES } from '@/constants/country-codes';
import * as F from '@/constants/feature-flags';

import * as T from './types';
import { txtForm } from './locales';

const MemoPrefixNumber = memo(function PrefixNumber(props) {
  const { selectedCountryCode, handleCountryCodeChange } = props;

  return (
    <Menu>
      <MenuButton
        {...T.SELECT_COMMON_PROPS}
        borderTopRightRadius="0"
        borderBottomRightRadius="0"
        fontWeight="normal"
        as={Button}
        rightIcon={<Icon as={BiChevronDown} />}
      >
        {`+${selectedCountryCode}`}
      </MenuButton>
      <MenuList maxHeight="200px" overflowY="scroll" zIndex={3}>
        {COUNTRY_CODES.map((country, index) => (
          <MenuItem
            key={country.dial_code + index}
            onClick={() => handleCountryCodeChange(country.dial_code)}
          >
            {`${country.name}: +${country.dial_code}`}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
});

MemoPrefixNumber.propTypes = {
  selectedCountryCode: string,
  handleCountryCodeChange: func.isRequired,
};

const MemoPhoneNumberList = memo(function PhoneNumberList(props) {
  const {
    createChangeHandler,
    selectedCountryCode,
    handleCountryCodeChange,
    errorPhone,
    lang,
    value,
  } = props;

  if (!F.RSVP_ENABLE_PHONE_NUMBER) return null;

  return (
    <FormControl isInvalid={errorPhone}>
      <FormLabel {...T.FORM_LABEL_PROPS}>{txtForm.hp[lang]}:</FormLabel>
      <InputGroup size="sm">
        <MemoPrefixNumber
          selectedCountryCode={selectedCountryCode}
          handleCountryCodeChange={handleCountryCodeChange}
        />
        <Input
          {...T.INPUT_COMMON_PROPS}
          borderTopLeftRadius="0"
          borderBottomLeftRadius="0"
          placeholder="..."
          type="number"
          value={value}
          onChange={createChangeHandler('phone_number')}
        />
      </InputGroup>
      <FormErrorMessage marginTop="4px">{errorPhone}</FormErrorMessage>
    </FormControl>
  );
});

MemoPhoneNumberList.propTypes = {
  createChangeHandler: func.isRequired,
  selectedCountryCode: string,
  handleCountryCodeChange: func.isRequired,
  errorPhone: string,
  lang: string,
  value: string,
};

const MemoPersonalizeList = memo(function PersonalList(props) {
  const { notes, lang, totalQuota, value, onChange } = props;
  const totalFinalQuota = F.ENABLE_GUEST_PERSONALIZATION ? totalQuota : 2;

  if (!F.ENABLE_PARTNER_MODE) return null;

  return (
    <FormControl>
      <FormLabel {...T.FORM_LABEL_PROPS}>
        {txtForm.willYouBringPartner[lang]} <br />
      </FormLabel>
      <Select {...T.SELECT_COMMON_PROPS} value={value} onChange={onChange}>
        {[...Array(totalFinalQuota).keys()].map((item, index) => {
          return (
            <option key={item} value={index + 1} style={{ color: 'black' }}>
              {index + 1}
            </option>
          );
        })}
      </Select>
      <FormHelperText {...T.FORM_HELPER_PROPS}>{notes}</FormHelperText>
    </FormControl>
  );
});

MemoPersonalizeList.propTypes = {
  notes: string.isRequired,
  lang: string.isRequired,
  totalQuota: number.isRequired,
  value: number.isRequired,
  onChange: func.isRequired,
};

export { MemoPrefixNumber, MemoPhoneNumberList, MemoPersonalizeList };
