import { TEXT_PROPS, DEFAULT_PROPS } from '@/constants/default-props';

export const TYPE = {
  YES: 'YA',
  NO: 'TIDAK',
};

export const ERROR_TYPE = {
  name: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
};

export const INPUT_COMMON_PROPS = {
  ...TEXT_PROPS.body,
  textAlign: 'left',
  isRequired: true,
  size: 'sm',
  type: 'text',
  backgroundColor: 'bgSecondary',
  color: 'secondaryColorText',
  fontSize: 'md',
  borderRadius: '4px',
  _placeholder: { color: 'secondaryColorText' },
  _focus: { borderColor: 'secondaryColorText' },
};

export const SELECT_COMMON_PROPS = {
  ...TEXT_PROPS.body,
  textAlign: 'left',
  fontSize: 'md',
  color: 'secondaryColorText',
  borderRadius: '4px',
  size: 'sm',
  backgroundColor: 'bgSecondary',
  _focus: { borderColor: 'secondaryColorText' },
};

export const FORM_LABEL_PROPS = {
  ...TEXT_PROPS.body,
  textAlign: 'left',
  margin: '16px 0 4px 0',
};

export const FORM_HELPER_PROPS = {
  ...TEXT_PROPS.body,
  textAlign: 'left',
  mt: '8px',
  fontSize: '16px',
  fontStyle: 'italic',
};

export const BUTTON_ATTEND = (isAttend) => ({
  ...DEFAULT_PROPS,
  width: '100%',
  size: 'sm',
  ...(isAttend && {
    colorScheme: 'blue',
    color: 'mainColorText',
  }),
});
