import React, { useState, useMemo, useCallback } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text,
  Heading,
  useToast,
  Image,
} from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import { useGuest } from '@/context/guest';
import useFormFields from '@/hooks/useFormFields';
import useRsvp from '@/usecase/use-rsvp';
import useAddNewGuest from '@/usecase/use-add-new-guest';

import { IMG_LOGO_WEDDING, IMG_CASTEL } from '@/constants/assets';
import { API_ATTEND_INFO } from '@/constants/api';
import { DEFAULT_PROPS } from '@/constants/colors';
import { RSVP_STATUS } from '@/constants/api';
import * as F from '@/constants/feature-flags';
import * as T from './types';
import { Title, txtForm, txtTitle } from './locales';

import { MemoPhoneNumberList, MemoPersonalizeList } from './_Utils';
import { TEXT_PROPS } from '@/constants/default-props';

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  const toast = useToast();
  const { guest, onUpdateGuest, isRegistered } = useGuest();
  const { onAddNewGuest } = useAddNewGuest();
  const { onUpdateRsvp } = useRsvp();

  const filteredEventInfo = useMemo(() => {
    if (!F.RSVP_ENABLE_ATTEND_INFO || !F.ENABLE_SHIFT_TIME_RECEPTION) return [];

    return API_ATTEND_INFO.filter((item) => item.shift.includes(guest.shift));
  }, [guest.shift]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorType, setErrorType] = useState(T.ERROR_TYPE);
  const { formFields, createChangeHandler } = useFormFields({
    name: `${isRegistered ? guest.name : ''}`,
    address: guest.address === '-' ? '' : guest.address || '',
    email: guest.email === '-' ? '' : guest.email || '',
    phone_number: guest.phone_number,
    guest_quota: guest.guest_quota,
    total_guest: guest.guest_confirm || guest.guest_quota || 1,
    is_attended: guest.status === 'NOT_ATTEND' ? T.TYPE.NO : T.TYPE.YES,
    attend_info: F.RSVP_ENABLE_ATTEND_INFO ? guest.attend_info || filteredEventInfo[0]?.value : '',
  });

  const [selectedCountryCode, setSelectedCountryCode] = useState(guest.country_code || '62');

  const handleCountryCodeChange = useCallback((code) => {
    setSelectedCountryCode(code);
  }, []);

  const callToasterError = (desc, title = 'Oops!') => {
    toast({
      title,
      description: desc,
      status: 'error',
      isClosable: true,
    });
  };

  const callToasterSuccess = (desc, title = txtForm.success[lang]) => {
    toast({
      title,
      description: desc,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  /**
   * function to submit to BE with check the form value first
   */

  const onSubmitForm = async () => {
    const {
      name,
      address,
      phone_number,
      total_guest,
      is_attended,
      email,
      attend_info,
    } = formFields; // reset error state
    setErrorType(T.ERROR_TYPE);
    // define current error state
    let currentErrorType = T.ERROR_TYPE;

    // Make sure user already input the value
    /**
     * ERROR CHECKING
     */
    if (F.RSVP_ENABLE_NAME && !name) {
      currentErrorType = { ...currentErrorType, name: txtForm.required[lang] };
    }

    if (F.RSVP_ENABLE_PHONE_NUMBER) {
      if (!phone_number) {
        currentErrorType = { ...currentErrorType, phone: txtForm.required[lang] };
      } else if (phone_number.length > 13 || phone_number.length < 5) {
        currentErrorType = { ...currentErrorType, phone: txtForm.invalidPhone[lang] };
      }
    }

    if (F.RSVP_ENABLE_ADDRESS && !address) {
      currentErrorType = { ...currentErrorType, address: txtForm.required[lang] };
    }

    if (F.RSVP_ENABLE_EMAIL) {
      if (!email) {
        currentErrorType = { ...currentErrorType, email: txtForm.required[lang] };
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        currentErrorType = { ...currentErrorType, email: txtForm.invalidEmail[lang] };
      }
    }

    if (
      currentErrorType.address ||
      // currentErrorType.email ||
      currentErrorType.name ||
      currentErrorType.phone
    ) {
      setErrorType(currentErrorType);
      return;
    }

    setIsLoading(true);
    const guestPayload = {
      name,
      email: email || '-',
      desc: guest.desc || '-',
      shift: guest.shift || '-',
      countryCode: selectedCountryCode,
      phoneNumber: phone_number || '-',
      address: address || '-',
      attendInfo: is_attended === T.TYPE.YES ? attend_info : '',
      system_info: 'WARNING_Terdapat Perubahan dari User_',
    };

    // Usecase: Group or General Invitation
    // We will `insert` instead of `update` if user doens't have code yet. But,
    // we will throw error if QR Invitation
    if (!guest.code) {
      if (F.ENABLE_QR_INVITATION) {
        setIsLoading(false);
        callToasterError(txtForm.msgError[lang]);
        return;
      }

      await onAddNewGuest({
        ...guestPayload,
        desc: guest.name || '-',
        guest_quota: guest.guest_quota,
        guest_confirm: total_guest && is_attended === T.TYPE.YES ? total_guest : 0,
        status: is_attended === T.TYPE.YES ? RSVP_STATUS.attend : RSVP_STATUS.notAttend,
        onSuccess: (guestCode) => {
          setIsLoading(false);
          callToasterSuccess(txtForm.msgSuccess[lang]);
          onUpdateGuest(guestCode, name);
        },
        onFailed: () => {
          setIsLoading(false);
          callToasterError(txtForm.msgError[lang]);
        },
      });

      return;
    }

    await onUpdateRsvp({
      ...guestPayload,
      isAttended: is_attended === T.TYPE.YES,
      totalGuest: total_guest && is_attended === T.TYPE.YES ? total_guest : 0,
      onSuccess: () => {
        setIsLoading(false);
        callToasterSuccess(txtForm.msgSuccess[lang]);
      },
      onFailed: () => {
        setIsLoading(false);
        callToasterError(txtForm.msgError[lang]);
      },
    });
  };

  return (
    <Box pos="relative" pb="42px" bgColor="bgPrimary" {...rest}>
      <Box margin="0 auto" maxW="360px" padding="24px" pos="relative" zIndex="2">
        <Box>
          <WithAnimation type="zoom">
            <Image w="120px" src={IMG_LOGO_WEDDING} margin="0 auto" />
          </WithAnimation>
          <Box mt="24px" width="100%">
            {/* Title & Desc Section */}
            <WithAnimation type="zoom">
              <Box m="16px 0 32px 0">
                <Heading {...TEXT_PROPS.title} marginTop="16px">
                  {Title[lang]}
                </Heading>
                <Text {...TEXT_PROPS.body} dangerouslySetInnerHTML={{ __html: txtTitle[lang] }} />
              </Box>
            </WithAnimation>
            <WithAnimation>
              <Box>
                {/* Form Sections - Name */}
                {F.RSVP_ENABLE_NAME && (
                  <FormControl margin="8px 0" isInvalid={errorType.name}>
                    <FormLabel {...T.FORM_LABEL_PROPS} margin="8px 0 0 0">
                      {txtForm.name[lang]}:
                    </FormLabel>
                    <Input
                      {...T.INPUT_COMMON_PROPS}
                      placeholder="..."
                      value={formFields.name}
                      onChange={createChangeHandler('name')}
                      disabled={isRegistered}
                    />
                    <FormLabel {...T.FORM_HELPER_PROPS}>{txtForm.nameHelper[lang]}</FormLabel>
                    <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
                  </FormControl>
                )}
                <MemoPhoneNumberList
                  value={formFields.phone_number}
                  createChangeHandler={createChangeHandler}
                  lang={lang}
                  selectedCountryCode={selectedCountryCode}
                  errorPhone={errorType.phone}
                  handleCountryCodeChange={handleCountryCodeChange}
                />

                {/* Form Sections - Address */}
                {F.RSVP_ENABLE_ADDRESS && (
                  <FormControl isInvalid={errorType.address}>
                    <FormLabel {...T.FORM_LABEL_PROPS}>{txtForm.address[lang]}:</FormLabel>
                    <Input
                      {...T.INPUT_COMMON_PROPS}
                      height="40px"
                      placeholder="..."
                      value={formFields.address}
                      onChange={createChangeHandler('address')}
                    />
                    <FormErrorMessage marginTop="4px">{errorType.address}</FormErrorMessage>
                  </FormControl>
                )}

                {/* Form Sections - Email */}
                {F.RSVP_ENABLE_EMAIL && (
                  <FormControl margin="8px 0" isInvalid={errorType.email}>
                    <FormLabel {...T.FORM_LABEL_PROPS}>Email:</FormLabel>
                    <Input
                      {...T.INPUT_COMMON_PROPS}
                      placeholder="..."
                      value={formFields.email}
                      onChange={createChangeHandler('email')}
                    />
                    <FormErrorMessage marginTop="4px">{errorType.email}</FormErrorMessage>
                  </FormControl>
                )}

                {/* Form Sections - Attendance */}
                <FormControl margin="8px 0">
                  <FormLabel {...T.FORM_LABEL_PROPS}>{txtForm.willYoutAttend[lang]}</FormLabel>
                  <Box display="flex" gap="16px" justifyContent="space-between">
                    <Button
                      {...T.BUTTON_ATTEND(formFields.is_attended === T.TYPE.YES)}
                      value={T.TYPE.YES}
                      onClick={createChangeHandler('is_attended')}
                    >
                      Gladly Attend
                    </Button>
                    <Button
                      {...T.BUTTON_ATTEND(formFields.is_attended === T.TYPE.NO)}
                      value={T.TYPE.NO}
                      onClick={createChangeHandler('is_attended')}
                    >
                      Unable to Attend
                    </Button>
                  </Box>
                </FormControl>

                {/* Form Section - Type of Event */}
                {F.RSVP_ENABLE_ATTEND_INFO && formFields.is_attended === T.TYPE.YES && (
                  <FormControl>
                    <FormLabel {...T.FORM_LABEL_PROPS}>Which event will you attend?</FormLabel>
                    <Select
                      {...T.SELECT_COMMON_PROPS}
                      value={formFields.attend_info}
                      onChange={createChangeHandler('attend_info')}
                    >
                      {filteredEventInfo.map((item) => {
                        return (
                          <option key={item.value} value={item.value} style={{ color: 'black' }}>
                            {item.title}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}

                {/* Form Sections - Partner */}
                {formFields.is_attended === T.TYPE.YES && (
                  <MemoPersonalizeList
                    lang={lang}
                    totalQuota={guest.guest_quota}
                    value={formFields.total_guest}
                    notes={guest.shift === 1 ? '*) Valid for 2 Person' : ''}
                    onChange={createChangeHandler('total_guest')}
                  />
                )}
                <Center>
                  <Button
                    {...DEFAULT_PROPS.button}
                    // FORCE DISABLED BUTTON RSVP
                    isDisabled={true}
                    isLoading={isLoading}
                    marginTop="24px"
                    type="button"
                    bgColor="bgSecondary"
                    // textTransform="uppercase"
                    onClick={onSubmitForm}
                    zIndex={2}
                  >
                    {txtForm.submit[lang]}
                  </Button>
                </Center>
              </Box>
            </WithAnimation>
          </Box>
        </Box>
      </Box>
      <Image
        zIndex="1"
        bottom="0"
        pos="absolute"
        opacity="0.1"
        src={IMG_CASTEL}
        alt=""
        height="400px"
        objectFit="cover"
        objectPosition="center"
      />
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);
